<template>
  <div class="mt-1">
    <b-row no-gutters class="mb-2">
      <div>
        {{ $t("page.groups.insertGroup") }}
      </div>
      <div class="ml-auto">
        {{ $t("page.groups.publish") }}
      </div>
    </b-row>
    <b-row no-gutters v-for="group in groups" :key="group._id">
      <b-form-checkbox
        v-model="group.active"
        @change="val => updateGroup(group, val, 'active')"
        >{{ group.name }}</b-form-checkbox
      >
      <base-switcher
        class="ml-auto"
        :disabled="!group.active"
        :value="group.role == 'writer'"
        sync
        @change="val => updateGroup(group, val, 'role')"
      />
    </b-row>
  </div>
</template>

<script>
import { remove } from "lodash";
import BaseSwitcher from "../Base/Switcher.vue";
export default {
  components: {
    BaseSwitcher
  },
  props: {
    joinedGroups: {
      type: Array,
      default: () => []
    },
    userId: {
      type: String,
      default: ""
    },
    new: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      groups: [],
      addedGroups: [],
      updatedGroups: [],
      removedGroups: []
    };
  },

  watch: {
    joinedGroups: {
      handler: "init",
      immediate: true,
      deep: true
    }
  },
  methods: {
    async init() {
      try {
        const groups = await this.$store.dispatch("groups/retrieveGroups");
        this.groups = [
          ...groups.map(g => {
            const groupFound = this.joinedGroups.find(jg => jg._id === g._id);
            return {
              _id: g._id,
              name: g.name,
              active: !!groupFound,
              role: groupFound?.userRole || "reader"
            };
          })
        ];
      } catch (error) {
        console.error(error);
      }
    },
    addGroup(group) {
      if (this.addedGroups.find(g => g._id === group._id)) return;
      this.addedGroups.push(group);
    },
    updateGroup(group, val, type) {
      if (type === "role") {
        group.role = group.role === "writer" ? "reader" : "writer";
        const foundGroup = this.joinedGroups.find(g => g._id === group._id);
        if (!foundGroup) return;
        const arrayToUpdate = this.new ? this.addedGroups : this.updatedGroups;
        const index = arrayToUpdate.findIndex(g => g._id === group._id);
        index > -1 ? (arrayToUpdate[index] = group) : arrayToUpdate.push(group);
      } else if (type === "active") {
        val ? this.addGroup(group) : this.removeGroup(group);
      }
      console.log(this.updatedGroups, this.addedGroups);
    },
    removeGroup(group) {
      const matchFun = g => g._id === group._id;
      if (this.joinedGroups.find(matchFun)) {
        this.removedGroups.push(group);
      }
      this.$nextTick(() => {
        remove(this.updatedGroups, matchFun);
        this.updatedGroups = [...this.updatedGroups];
        remove(this.addedGroups, matchFun);
        this.addedGroups = [...this.addedGroups];
      });
    },
    async saveChanges(userId) {
      const promises = [];
      this.removedGroups.forEach(g => {
        promises.push(
          this.$store.dispatch("groups/removeGroupMembers", {
            groupId: g._id,
            payload: { usersIds: [userId || this.userId] }
          })
        );
      });

      this.addedGroups.forEach(g => {
        promises.push(
          this.$store.dispatch("groups/addGroupMember", {
            groupId: g._id,
            payload: {
              members: [
                {
                  role: g.role,
                  userId: userId || this.userId
                }
              ]
            }
          })
        );
      });

      this.updatedGroups.forEach(g => {
        promises.push(
          this.$store.dispatch("groups/updateGroupMembers", {
            groupId: g._id,
            payload: {
              usersRoles: [
                {
                  role: g.role,
                  userId: userId || this.userId
                }
              ]
            }
          })
        );
      });
      try {
        await Promise.all(promises);
        this.addedGroups = [];
        this.removedGroups = [];
        this.updatedGroups = [];
        this.groups = [];
        return true;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
.search-dropdown {
  position: absolute;
  top: 35px;
  width: 100%;
  background: white;
  box-shadow: 0 1px 2px 2px rgba(61, 61, 61, 0.1);
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 100;
}
</style>
