import { render, staticRenderFns } from "./UserGroupManagement.vue?vue&type=template&id=c21f9c20&scoped=true&"
import script from "./UserGroupManagement.vue?vue&type=script&lang=js&"
export * from "./UserGroupManagement.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupManagement.vue?vue&type=style&index=0&id=c21f9c20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c21f9c20",
  null
  
)

export default component.exports